import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"
import ChartController from "./chart_controller"

export default class extends ChartController {
  static targets = ["chart", "counter", "dropdown"]

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))

    const options = {
      colors: ["#FB923C", "#EF4444", "#A25906"],
      chart: {
        height: "350px",
        type: "line",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            if(dataPointIndex > 0){
              let pv = series[seriesIndex][dataPointIndex - 1]
              let percent_change = (100 * (value - pv)/pv).toFixed(2)
              percent_change = percent_change > 0 ? `+${percent_change}`: percent_change
              return `${value} ${percent_change}%`
            }
            else{
              return value
            }
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 6,
        curve: "smooth"
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 20,
          right: 20,
          top: -26
        },
      },
      series: data,
      legend: {
        show: true,
        position: "top",
        formatter: (seriesName, opts) => {
          const lastVal = opts.w.globals.initialSeries[opts.seriesIndex].data.at(-1).y
          let total = 0
          for(let series of opts.w.globals.series){
            if(series.at(-1))
              total += series.at(-1)
          }
          this.counterTarget.setAttribute("value", total)
          if(!this.animating)
            this.animateCounter()
          if(opts.w.globals.collapsedSeriesIndices.includes(opts.seriesIndex))
            return `
            <div class="flex flex-row gap-1 disabled text-sm font-normal me-1">
              <svg style="width: 1em; height: 1em; vertical-align: -0.125em;" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M384 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l320 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z"/>
              </svg>
              <span>${seriesName}:</span> <span class="font-semibold">${lastVal}</span>
            </div>
          `
          else
            return `
              <div class="flex flex-row gap-1 disabled text-sm font-normal me-1">
                <svg style="width: 1em; height: 1em; vertical-align: -0.125em;" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm-204.7-98.1l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L184 302.7l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.3 16.4 6.3 22.6 0z"/>
                </svg>
                <span class="text-sm font-normal me-1">${seriesName}:</span> <span class="font-semibold">${lastVal}</span>
              </div>
            `
        },
        labels: {
          useSeriesColors: true
        },
        markers: {
          size: 0,
          customHTML: function() {
            return ""
          }
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-current text-gray-500 dark:text-gray-200"
          },
          hideOverlappingLabels: true,
          showDuplicates: false,
          datetimeUTC: true,
          format: "MMM dd ",
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
    }

    this.chartObj = new ApexCharts(chart, options)
    this.chartObj.render()

    // Default View
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 7)
    const endDate = new Date()
    endDate.setDate(endDate.getDate() - 1)
    this.chartObj.zoomX(startDate.getTime(), endDate.getTime())
    initFlowbite()
  }
}
