import { Controller } from "@hotwired/stimulus"
import { Dropdown } from "flowbite"

export default class extends Controller {
  static targets = [
    "dropdown",
    "dropdownTrigger"
  ]

  static values = {
    showOn: { type: String, default: "click" },
    placement: { type: String, default: "bottom" }
  }

  connect() {
    this.dropdown = new Dropdown(
      this.dropdownTarget,
      this.dropdownTriggerTarget, {
        placement: this.placementValue, triggerType: this.showOnValue
      }
    )
  }

  disconnect() {
    this.dropdown.destroy()
  }
}
