import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static chartObj
  static animating = false
  animationSpeed = 150

  handleDateRange(e) {
    const target = $(e.target)
    const numDays = parseInt(target.data("days"))
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - numDays)
    const endDate = new Date()
    endDate.setDate(endDate.getDate() - 1)
    this.chartObj.zoomX(startDate.getTime(), endDate.getTime())
    this.dropdownTarget.childNodes[0].nodeValue = target.text()
  }

  animateCounter() {
    this.animating = true
    const value = parseInt(this.counterTarget.getAttribute("value"))
    const data = parseInt(this.counterTarget.innerText.replace(/,/g, ""))
    const time = Math.abs((value - data) / this.animationSpeed)

    if(data > value){
      this.animateDown(time)
    }
    else{
      this.animateUp(time)
    }
  }

  animateUp(time){
    const value = parseInt(this.counterTarget.getAttribute("value"))
    const data = parseInt(this.counterTarget.innerText.replace(/,/g, ""))

    if(data < value) {
      this.counterTarget.innerText = this.numberWithCommas(Math.ceil(data + time))
      setTimeout(() => {
        this.animateUp(time)
      }, 1)
    }
    else{
      this.counterTarget.innerText = this.numberWithCommas(value)
      this.animating = false
    }
  }

  animateDown(time){
    const value = parseInt(this.counterTarget.getAttribute("value"))
    const data = parseInt(this.counterTarget.innerText.replace(/,/g, ""))

    if(data > value) {
      this.counterTarget.innerText =  this.numberWithCommas(Math.ceil(data - time))
      setTimeout(() => {
        this.animateDown(time)
      }, 1)
    }
    else{
      this.counterTarget.innerText = this.numberWithCommas(value)
      this.animating = false
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}
