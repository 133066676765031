import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"
import colors from "tailwindcss/colors"

export default class extends Controller {

  static targets = [
    "donutChart"
  ]

  static values = {
    dataValues: { type: Array },
    colors: { type: Array },
    labels: { type: Array },
    legendIcon: { type: Object }
  }

  connect() {
    this.chartOptions = {
      series: this.dataValuesValue,
      colors: this.colorsValue,
      chart: {
        height: "100%",
        width: "100%",
        type: "donut",
      },
      stroke: {
        colors: ["white"],
        show: true,
        width: 2,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 20,
              },
              total: {
                show: true,
                label: "Total",
                color: colors.gray[500],
              },
              value: {
                color: colors.gray[500],
                offsetY: -20,
              },
            },
            size: "60%",
          },
        },
      },
      labels: this.labelsValue,
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        fillSeriesColor: false,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          return (
            "<div class='icon-regular icon-before icon-circle' style='color:" + w.globals.colors[seriesIndex] + "'></div><span style='padding: 5px'>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            "</span>"
          )
        }
      },
      legend: {
        show: true,
        formatter: (seriesName, opts) => {
          let seriesIndex = opts.w.globals.labels.indexOf(seriesName)
          return ["<div class='flex flex-row items-center'>", this.legendIconValue[seriesName], "<div style='padding: 0 30px 0 5px;'>", seriesName, "</div><div style='flex-grow: 1; text-align: right;'>", opts.w.globals.seriesTotals[seriesIndex] ,"</div></div>"]
        },
        labels: {
          colors: colors.gray[500]
        },
        itemMargin: {
          horizontal: 0,
          vertical: 2,
        },
        markers: {
          size: 0
        }
      },
    }

    this.chart = new ApexCharts(this.donutChartTarget, this.chartOptions)
    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
  }
}
