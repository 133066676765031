import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectAllCheckbox",
    "selectCheckbox"
  ]

  connect() {
    this._broadcastSelectionsChanged()
  }

  get selectedValues() {
    return this.selectedCheckboxes.map((input) => input.value)
  }

  get selectedCheckboxes() {
    return this.selectCheckboxTargets.filter((input) => input.checked)
  }

  get selectedRows() {
    return this.selectedCheckboxes.map((input) => input.closest("tr"))
  }

  toggleAllRows() {
    const checked = this.selectAllCheckboxTarget.checked

    for (const checkbox of this.selectCheckboxTargets) {
      checkbox.checked = checked
    }
    this._broadcastSelectionsChanged()
  }

  toggleRow() {
    this.selectAllCheckboxTarget.checked = this.selectCheckboxTargets.every(
      (checkbox) => checkbox.checked
    )
    this._broadcastSelectionsChanged()
  }

  _broadcastSelectionsChanged() {
    this.dispatch("selectionsChanged", {
      detail: {
        values: this.selectedValues,
        checkboxes: this.selectedCheckboxes,
        rows: this.selectedRows
      }
    })
  }
}
