let dynamicFormContext = (elt, innerElements, value) => {
  let context;
  let hasSnowPlowFields = false;
  const formData = {};
  if (value === undefined) {
    const formElements = elt.querySelectorAll("[data-snowplow-fieldname]");
    formElements.forEach((element) => {
      const fieldName = element.getAttribute("data-snowplow-fieldname");
      if (fieldName) {
        hasSnowPlowFields = true;
        formData[fieldName] = element?.value;
      }
    });
    context = {
      schema: "iglu:com.huntress/user_profile/jsonschema/1-0-0",
      data: formData,
    };
  } else {
    const fieldName = elt.getAttribute("data-snowplow-fieldname");
    if (fieldName) {
      formData[fieldName] = value;
      context = {
        schema: "iglu:com.huntress/user_profile/jsonschema/1-0-0",
        data: formData,
      };
    }
  }
  return context;
};

function initSnowplowTracker(method, trackerNamespace, collectorEndpoint, appId, schemaEndpoint) {
  (function (p, l, o, w, i, n, g) {
    if (!p[i]) {
      p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
      p.GlobalSnowplowNamespace.push(i);
      p[i] = function () {
        (p[i].q = p[i].q || []).push(arguments);
      };
      p[i].q = p[i].q || [];
      n = l.createElement(o);
      g = l.getElementsByTagName(o)[0];
      n.async = 1;
      n.src = w;
      g.parentNode.insertBefore(n, g);
    }
  })(window, document, "script", "https://static.huntresscdn.com/js/3_24_2_kzhk9kjvjzpzdnk61lg3.js", method);
  window[method]("newTracker", trackerNamespace, collectorEndpoint, {
    // Initialize a tracker
    appId: appId,
    discoverRootDomain: true,
    cookieSameSite: "Lax",
    contexts: {
      performanceTiming: true,
      webPage: true,
      gaCookies: false,
    },
  });
  window[method]("addPlugin", "https://static.huntresscdn.com/js/3_24_3_bea5f7e15ab6e3258753b4ae901bf7.js", [
    "snowplowButtonClickTracking",
    "ButtonClickTrackingPlugin",
  ]);

  window[method]("enableFormTracking");
  window[method]("enableActivityTracking", { minimumVisitLength: 10, heartbeatDelay: 10 });
  window[method]("enableLinkClickTracking", { pseudoClicks: true, trackContent: true });
  window[method]("enableButtonClickTracking");
  window[method]("setUserId", document.querySelector('meta[name="user_id"]')?.content);
  window.addEventListener("load", () => {
    try {
      window[method]("trackPageView");
    } catch (error) {
      console.error("snowplow:trackPageView capture error", error);
    }
  });
  window.addEventListener("mutiny:experience-impression", function (event) {
    try {
      const mutinyExperience = event.detail;
      let context = [
        {
          schema: schemaEndpoint,
          data: {
            mutiny_audience_segment: mutinyExperience.audienceSegment,
            mutiny_experience: mutinyExperience.experience,
            mutiny_impression_type: mutinyExperience.impressionType,
            mutiny_page: mutinyExperience.page,
            mutiny_variation_key: mutinyExperience.variationKey,
            mutiny_variation_name: mutinyExperience.variationName,
          },
        },
      ];
      window[method]("addGlobalContexts", context);
    } catch (error) {
      console.error("snowplow:addGlobalContexts capture error", error);
    }
  });

  // Check for dynamic content
  const observer = new MutationObserver(function (records) {
    for (const record of records) {
      if (record.type === "childList" && record.addedNodes.length > 0) {
        for (const node of record.addedNodes) {
          if (node.nodeName === "FORM") {
            window[method]("enableFormTracking");
          }
          if (node.nodeName === "A") {
            window[method]("refreshLinkClickTracking");
          }
        }
      }
    }
  });
  observer.observe(document, { childList: true, subtree: true });
}

const BDP_ENDPOINT = document.getElementsByTagName('meta')[name="snowplow_bdp_endpoint"].content;
const APP_ID = document.getElementsByTagName('meta')[name="snowplow_app_id"].content;

initSnowplowTracker("snowplow", "cf2", BDP_ENDPOINT, APP_ID, "iglu:com.huntress/mutiny/jsonschema/1-0-0");
